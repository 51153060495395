<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
export default {
  created () {
    window.open(process.env.VUE_APP_LINKEDIN_URL, '_self')
  },
  beforeMount () {
    this.redirectToLinkedInPage()
  },
  methods: {
    redirectToLinkedInPage () {
      window.open(process.env.VUE_APP_LINKEDIN_URL, '_self')
    }
  }
}
</script>
<style>
  html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  html::-webkit-scrollbar {
    display: none;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
